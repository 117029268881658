<template lang="pug">
  section.l-section.wc-breadcrumb(:class='showBreadcrumb')
    .l-section-h.d-flex.align-items-center
      i.fa-fw.fa-15x(:class='icon')
      b-breadcrumb#breadcrumb-main.d-inline-flex.bg-transparent.m-0.py-0(:items='breadcrumb')
</template>

<script>
export default {
  name: 'wc-breadcrumb',
  props: {
    icon: {
      type: String,
      default: 'angle-right',
    },
  },
  computed: {
    showBreadcrumb() {
      let style = []
      switch (this.remove) {
        case true:
          style.push('d-none')
          break
        case false:
          style.push('d-block')
          break
        default:
          if (typeof this.remove === 'string') style.push(['d', this.remove, 'none'].join('-'))
      }
      switch (this.show) {
        case true:
          style.push('d-block')
          break
        case false:
          style.push('d-none')
          break
        default:
          if (typeof this.show === 'string') style.push(['d', this.show, 'block'].join('-'))
      }
      return [...new Set(style)].join(' ')
    },
  },
  methods: {
    processBreadcrumb() {
      this.breadcrumb = this.$route.matched
        .filter((matched) => matched.meta && matched.meta.breadcrumb)
        .map((matched) => {
          return matched.meta.breadcrumb instanceof Function
            ? matched.meta.breadcrumb(this.$i18n, this.$route.params)
            : matched.meta.breadcrumb
        })
        .flat()
      this.remove = this.$getDeep(this.$route.meta, 'config.breadcrumb.remove')
      this.show = this.$getDeep(this.$route.meta, 'config.breadcrumb.show')
    },
  },
  watch: {
    $route: 'processBreadcrumb',
  },
  data() {
    return {
      remove: true,
      show: false,
      breadcrumb: [],
    }
  },
  created() {
    this.processBreadcrumb()
  },
}
</script>

<style lang="scss">
.wc-breadcrumb #breadcrumb-main a {
  cursor: pointer;
}
</style>
